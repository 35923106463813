<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title: "{name} :: Import Drones"
page.header : "Import New Drones"
page.description : "ทำการ Import Drone ที่ผลิตขึ้นสำหรับ Lot {name} เข้ามาในระบบ โดยจะ Excel File นี้ต้องสร้างตามแบบที่กำหนด"

import.data.error.title : "ข้อมูลผิดพลาด"
import.data.error : "ข้อมูลที่นำเข้าระบบมีข้อผิดพลาด กรุณาตรวจสอบ"
import.file.error.title : "ไฟล์ผิดพลาด"
import.confirm.title : "ยืนยันการ Import"
import.confirm.content : "คุณยืนยันที่จะนำข้อมูล Drone ชุดใหม่ เข้าระบบ ? (ไม่สามารถแก้ไขภายหลังได้)"
import.confirm.success.title : "Import เรียบร้อย"
import.confirm.success.content : "ทำการนำข้อมูล Drone ชุดใหม่ จำนวน {num} @:common.drone.unit เข้าระบบเป็นที่เรียบร้อย"
import.confirm.error.title : "นำเข้าข้อมูลผิดพลาด"

upload.label : "Upload Excel"
error_only.label : "แสดงที่ผิดพลาด"
warning_only.label : "แสดงที่เตือน"
confirm.label : "นำข้อมูลเข้าระบบ"

button.export_template : "Download ตัวอย่าง Excel"
</i18n>
<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header',{ name: lot.lot_name })"
			:description="$t('page.description',{  name: lot.lot_name })"/>
		<a-alert v-if="hasErrors" type="error"
			style="margin-bottom:8px;"
			:message="errorMessageTitle"
			:description="errorMessage"
			show-icon/>
		<div class="myform-action-pane">
			<a-upload
				:disabled="uploading"
				accept=".xlsx"
				:show-upload-list ="false"
				:before-upload="uploadFile">
				<a-button :loading="uploading"><a-icon type="upload" />{{$t('upload.label')}}</a-button>
			</a-upload>

			<a-button
				icon="download"
				@click="clickExportTemplate">
				{{$t('button.export_template')}}
			</a-button>
		</div>
		<a-card v-if="importSuccess" :bordered="false" :loading="uploading">
			<div style="margin-bottom:8px;">
				<a-checkbox v-model="filter.errorOnly">
					{{$t('error_only.label')}}
				</a-checkbox>
				<a-checkbox v-model="filter.warningOnly">
					{{$t('warning_only.label')}}
				</a-checkbox>
			</div>
			<a-button :disabled="!canConfirmImport" class="btn-page-action"
				type="primary"
				@click="handleConfirmImport">
				{{$t("confirm.label")}}
			</a-button>

			<a-table
				bordered
				class="mytable no-hover table-import table-drone-import"
				:row-key="record => record.excel_row"
				:columns="tableColumns"
				:data-source="displayImportDrones"
				:pagination="{ pageSize: 25 ,currentPage: currentPage,hideOnSinglePage: true }"
				size="small">
				<span slot="excel_row" slot-scope="text,record">
					{{text}}
					<ImportNoticeIcon :model="record" name="excel_row" />
				</span>
				<span slot="name" slot-scope="text,record">
					{{text}}
					<ImportNoticeIcon :model="record.drone" name="name" />
				</span>

				<span slot="serial_no" slot-scope="text,record">
					{{text}}
					<ImportNoticeIcon :model="record.drone" name="serial_no" />
				</span>
				<span slot="pixhawk_serial_no" slot-scope="text,record">
					{{text}}
					<ImportNoticeIcon :model="record.drone" name="pixhawk_serial_no" />
				</span>
				<span slot="firmware_name" slot-scope="text,record">
					{{text}}
					<ImportNoticeIcon :model="record.drone" name="firmware_name" />
				</span>
				<div slot="flight_time" slot-scope="text,record">
					<FlightTimeView
							:time-seconds="text"/>
					<ImportNoticeIcon :model="record.drone" name="latest_flight_time_sec" />
				</div>
				<div slot="partItem" slot-scope="text,record,index,column">
					<DataWithFeedback
						:content="text"
						:warning="getPartWarning(record,column.key)"/>
				</div>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ImportNoticeIcon from "@components/common/ImportNoticeIcon.vue"
import DataWithFeedback from "@components/common/DataWithFeedback.vue"
import FlightTimeView from "@components/common/FlightTimeView.vue"
import {Upload,Table,Checkbox} from "ant-design-vue"
import axios from "axios"
import fileDownload from "js-file-download"
import ApiError from '@utils/errors/ApiError'
import { mapActions } from 'vuex'
import { isVarEmpty } from '@utils/stringUtil'
import { lookup as _lookup } from "@utils/objectUtil"
export default {
	page() {
		return {title : this.$t('page.title',{ name: this.lot.lot_name })}
	} ,
	components : {
		"a-upload" : Upload , "a-checkbox" : Checkbox,
		"a-table" : Table,
		ImportNoticeIcon, DataWithFeedback, FlightTimeView
	} ,
	mixins : [PageMixin] ,
	props : {
		model : {
			type: null,
			required: true,
			default : () => []
		} ,
		lot : {
			type: null,
			required: true,
			default : () => []
		} ,
	} ,
	data() {
		return {
			uploading : false,
			hasErrors : false,
			importSuccess : false,
			importDrones : [] ,
			dataBucket : [],
			errorMessageTitle : null,
			errorMessage : null,
			currentPage : 1,
			filter : {
				errorOnly : false,
				warningOnly : false
			}
		}
	} ,
	computed : {
		displayImportDrones() {
			return this.importDrones.filter((importData) => {
				let show = true
				if (this.filter.errorOnly)
					show = !isVarEmpty(importData.drone.errors)
				if (this.filter.warningOnly)
					show = !isVarEmpty(importData.warning_list);
				return show
			});
		} ,
		canConfirmImport() {
			if (!this.importSuccess || this.hasErrors)
				return false;
			return this.importDrones.length > 0;
		} ,
		tableColumns() {
			const columns =  [
				{
					dataIndex : 'excel_row' ,
					title : this.$t('common.table.column.excel_row'),
					align : 'center',
					width : 50,
					scopedSlots : {customRender : 'excel_row'}
				} ,
				{
					dataIndex : 'drone.name' ,
					title : this.$t('drone.field.name') ,
					width : 150,
					scopedSlots : {customRender : 'name'}
				} ,
				{
					dataIndex : 'drone.serial_no' ,
					title : this.$t('drone.field.serial_no') ,
					width : 135,
					scopedSlots : {customRender : 'serial_no'}
				} ,
				{
					dataIndex : 'drone.pixhawk_serial_no' ,
					title : this.$t('drone.field.pixhawk_serial_no') ,
					width : 135,
					scopedSlots : {customRender : 'pixhawk_serial_no'}
				} ,
				{
					dataIndex : 'drone.firmware_name' ,
					title : this.$t('drone.field.firmware') ,
					width : 100,
					scopedSlots : {customRender : 'firmware_name'}
				} ,
				{
					dataIndex : 'drone.latest_flight_time_sec' ,
					title : this.$t('drone.field.latest_flight_time_sec'),
					width : 135,
					scopedSlots : {customRender : 'flight_time'}
				} ,
			];
			for(const key in this.dataBucket.part_items) {
				const partItem = this.dataBucket.part_items[key];
				const part = _lookup(this.dataBucket,'parts',partItem.part_id);

				let columnTitle = part.name+" #"+partItem.part_index
				columnTitle += "\n(SKU : "+part.sku+")"
				columns.push({
					dataIndex : "serial_no_list."+partItem.key ,
					title : columnTitle,
					class : 'part-item-col' ,
					align : 'center',
					key : partItem.key,
					width : 135 ,
					scopedSlots : {customRender : 'partItem'} ,
				})
			}
			return columns;
		}
	} ,
	methods :{
		...mapActions('app',['collapsedSideMenu']) ,
		getPartWarning(data,key) {
			if (data.warning_list && key in data.warning_list) {
				return data.warning_list[key]
			} else {
				return undefined
			}
		} ,
		clickExportTemplate() {
			this.showPageLoading(true)
			axios.get("/api/drone-lots/"+this.lot.id+"/export-drones-template",{
				responseType: 'blob'
			}).then((response) => {
				fileDownload(response.data,"drones_create_"+this.lot.lot_name+".xlsx");
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
					this.hidePageLoading()
			})
		} ,
		uploadFile(file) {
			this.uploading = true;
			const formData = new FormData();
			formData.append('file',file);

			axios.post("/api/drone-lots/"+this.lot.id+"/import-new-drones",formData).then((response)=>{
				this.importDrones = response.data.data.import_data;
				this.hasErrors = response.data.data.has_errors;
				this.dataBucket = response.data.data.bucket;

				this.importSuccess = true;
				this.filter.errorOnly = false;
				this.filter.warningOnly = false;
				this.currentPage = 1;
				if (this.hasErrors) {
					this.errorMessageTitle = this.$t('import.data.error.title');
					this.errorMessage = this.$t('import.data.error')
				}
				this.collapsedSideMenu(true);
			}).catch((error)=>{
				this.hasErrors = true;
				this.errorMessageTitle = this.$t('import.file.error.title');
				this.errorMessage = ApiError.getDisplayErrorMessage(this,error);
			}).finally(()=>{
				this.uploading = false
			})
			return false;
		} ,
		handleConfirmImport() {
			if (this.hasErrors)
				return;

			this.$confirm({
				title : this.$t('import.confirm.title') ,
				content : this.$t('import.confirm.content') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk : () => {
					this.showPageLoading()

					const formData = this.importDrones.map((importData) => {
						return {
							'drone' : importData.drone,
							'serial_no_list' : importData.serial_no_list ,
						}
					});

					axios.post("/api/drone-lots/"+this.lot.id+"/import-new-drones-confirm",formData).then((response)=>{
						const successTitle = this.$t('import.confirm.success.title');
						const successContent = this.$t('import.confirm.success.content',{num : response.data.data.num_drones});


						this.$notification.success({
							message :  successTitle,
							description : successContent,
						});
						this.$router.push({name: 'drone_lot/usages'})
					}).catch((error) => {
						this.hasErrors = true;
						this.errorMessageTitle = this.$t('import.confirm.error.title');
						this.errorMessage = ApiError.getDisplayErrorMessage(this,error)
					}).finally(()=>{
						this.hidePageLoading();
					})
				} ,
				onCancel() {}
			});
		}
	}

}
</script>

<style lang="less" scoped>
.table-drone-import::v-deep {
	th.part-item-col {
		white-space: pre-line;
	}
}
</style>
